@import 'utility/variables';

button.toggle-sidenav {
  height: 50px;
  width: 50px;
  line-height: 50px;

  &:hover,
  &:active,
  &:focus {
    border: none;
    background-color: unset;
  }
}

.sidenav-component-container {
  display: none;
}

.sidenav-header {
  padding: 5px 5px 5px 25px;

  // background: #f2f0f9;
  .left-column {
    width: 300px;

    .page-title {
      line-height: 30px;
    }
  }

  .right-column {
    padding: 0;
  }
}

.mat-drawer-opened {
  overflow: auto !important;

  .sidenav-header {
    padding-right: 20px;

    .toggle-sidenav {
      transform: rotate(180deg);
    }
  }

  .sidenav-component-container {
    display: block;
    height: calc(100% - 85px);
    overflow-y: auto;

    // background-color: #f2f0f9;
  }
}

.sidenav-container {
  width: $sidenav-width;
  overflow: visible !important;

  .mat-drawer-inner-container {
    overflow: visible;
  }
}

.mat-drawer-container {
  background-color: white !important;
}

.mat-drawer {
  visibility: visible !important;
  display: block !important;
}

.mat-sidenav-content {
  overflow: unset !important;
  background: white;
  height: 100%;
}

#tree-search-input {
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  border: 1px solid $primary-color;
  padding: 0.5em 2em;
}

.tree-node-label {
  color: var(--primary-text-color) !important;
}

.search-container {
  border-bottom: 1px solid $primary-color;
  justify-content: center;
  align-content: center;
  padding-left: 2em;
  padding-bottom: 0.5em;
}
.table-container {
  max-height: 100% !important;
  margin-top: 0px !important;
  padding: 5px 0px 0px !important;
}
.tree {
  padding-left: 0px !important;
}
.cdk-virtual-scroll-content-wrapper {
  padding-left: 2em;
}
@media screen and (max-width: 768px) {
  button.toggle-sidenav {
    height: 40px;
    line-height: 40px;
  }

  .mat-drawer-opened {
    width: 100%;

    button.toggle-sidenav {
      height: 50px;
      line-height: 50px;
    }
  }

  .sidenav-header {
    padding: 6px 6px 6px 25px;
  }

  .mat-sidenav-content {
    margin-left: 0;
  }
}
