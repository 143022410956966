.w-max-content {
  width: max-content;
}

.w-auto {
  width: auto;
}

.full {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  /* stylelint-disable-line */
  width: 50%;
}

.h-full {
  height: 100%;
}

.h-1\/2 {
  /* stylelint-disable-line */
  height: 50%;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

$sides: (top, bottom, left, right);
@for $space from 0 through 40 {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space * 0.25}rem !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space * 0.25}rem !important;
    }
  }
  .m-#{$space} {
    margin: #{$space * 0.25}rem !important;
  }

  .p-#{$space} {
    padding: #{$space * 0.25}rem !important;
  }
}
